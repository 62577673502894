function Header() {
    return (
        <div className="header">
            <div className="logo">
                <p className="kiss">KIS</p>
            </div>
            <div className="logo">
                 <p className="kiss">KIS</p>
            </div>
        </div>
    )
}

export default Header;