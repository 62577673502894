function Main() {
    return (
        <div>
            <p className="news-text">COMING SOON</p>
            <div className="info">
                <p className="info-text">
                    ONBOARDING ON TAP. BTC MAXIS, ETH MINIS, REGULAR SIZED SOLS, IT'S ALL DOESN'T MATTER. COME TO TAP.
                </p>
            </div>
        </div>
    )
}

export default Main;